import events from 'events.js';
import hotjar from 'hotjar.js';
import {setupMenu} from 'menu.js';
import {HF_COOKIE} from 'core/constants.js';
import {localStorageRemoveItem} from 'core/utils.js';

function clearCookies() {
    localStorageRemoveItem(HF_COOKIE);
}

function trackHotjarUserType() {
    const type = document.body.dataset.userType;
    const isIntegrated = document.body.dataset.client;
    if (type === 'org') {
        hotjar('trigger', 'user-authenticated');
        hotjar('trigger', 'user-type-org');
        hotjar('trigger', 'user-type-not-member');
        hotjar('trigger', 'user-type-not-gp');
        hotjar('trigger', 'user-type-not-hcp');
    } else if (type === 'gp') {
        hotjar('trigger', 'user-authenticated');
        hotjar('trigger', 'user-type-not-org');
        hotjar('trigger', 'user-type-not-member');
        hotjar('trigger', 'user-type-gp');
        hotjar('trigger', 'user-type-hcp');
    } else if (type === 'hcp') {
        hotjar('trigger', 'user-authenticated');
        hotjar('trigger', 'user-type-not-org');
        hotjar('trigger', 'user-type-not-member');
        hotjar('trigger', 'user-type-not-gp');
        hotjar('trigger', 'user-type-hcp');
    } else if (type === 'member') {
        hotjar('trigger', 'user-authenticated');
        hotjar('trigger', 'user-type-not-org');
        hotjar('trigger', 'user-type-member');
        hotjar('trigger', 'user-type-not-gp');
        hotjar('trigger', 'user-type-not-hcp');
    } else {
        hotjar('trigger', 'user-not-authenticated');
    }
    if (isIntegrated) {
        hotjar('trigger', 'is-integrated');
    } else {
        hotjar('trigger', 'not-integrated');
    }
}

(async function () {
    await events.ready();
    setupMenu();
    trackHotjarUserType();
    clearCookies();
})();
