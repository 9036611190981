import events from 'events.js';
import {localStorageGetItem, localStorageSetItem} from 'core/utils.js';

(async function () {
    await events.ready();
    let messages = document.querySelectorAll('.messages .message.real');
    const html = '<i class="fa fa-times u-cursor"></i>';
    for (let message of messages) {
        if (
            message.innerHTML.includes('Nookal') &&
            !localStorageGetItem('booking-message-closed')
        ) {
            message.style.display = 'block';
            message.insertAdjacentHTML('afterbegin', html);
        }
    }
    messages = document.querySelectorAll('.messages .message > .fa-times');
    for (let message of messages) {
        events.listen(message, 'click', function () {
            const elem = message.parentNode;
            if (elem.innerHTML.includes('Nookal')) {
                localStorageSetItem('booking-message-closed', true);
            }
            elem.style.display = 'none';
            document.getElementById('hidden-' + elem.id).style.display =
                'none';
            // To ensure a flash message will not be displayed again after being closed:
            //   1. Combine all flash message classes into a string (Array.from(elem.classList).join(','))
            //   2. Set cookie value as 'False' for combined flash message classes
            //   3. Update context processor to find specific class name
            document.cookie =
                Array.from(elem.classList).join(',') +
                '=False;Path=/;Domain=' +
                window.location.hostname;
        });
    }
})();
